$logo-big: 80px;
$logo: 40px;
$big-padding: 100px;
$collapse: 700px;
$sticky: true;

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  box-shadow: var(--shadow);

  @if $sticky {
    position: sticky !important;
    top: 0;
    z-index: 10 !important;
  }
}

header a {
  color: var(--text);
  text-decoration: none;
}

.home {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.logo {
  height: $logo;
}

.logo > * {
  height: 100%;
}

.title-text {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 5px;
  min-width: 0;
  font-family: var(--title);
  text-align: left;
}

.title {
  font-size: var(--large);
}

.subtitle {
  opacity: 0.65;
  font-weight: var(--thin);
}

.nav-toggle {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0;
  color: var(--text);
  appearance: none;
  transition: background var(--transition);
}

.nav-toggle:after {
  content: "\f0c9";
  position: absolute;
  left: 50%;
  top: 50%;
  color: var(--text);
  font-size: 15px;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  transform: translate(-50%, -50%);
}

.nav-toggle:checked:after {
  content: "\f00d";
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  font-family: var(--heading);
  text-transform: uppercase;
}

nav > a {
  padding: 5px;
}

nav > a:hover {
  color: var(--primary);
}

header:not([data-big]) {
  @media (max-width: $collapse) {
    justify-content: flex-end;

    .nav-toggle {
      display: flex;
    }

    .nav-toggle:not(:checked) + nav {
      display: none;
    }

    nav {
      align-items: flex-end;
      flex-direction: column;
      width: 100%;
    }
  }
}

header[data-big] {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: $big-padding 20px;

  @if $sticky {
    top: unset;
  }

  .home {
    flex-direction: column;
    flex-grow: 0;
  }

  .logo {
    height: $logo-big;
  }

  .title-text {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .title {
    font-size: var(--xxl);
  }

  .subtitle {
    font-size: var(--large);
  }
}
